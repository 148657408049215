import pvcdoor from "./1.webp";
import pvcdoor1 from "./2.webp";
import pvcdoor2 from "./3.webp";
import pvcdoor3 from "./4.webp";
import pvcdoor4 from "./5.webp";
import pvcdoor5 from "./6.webp";
import pvcDoorVideo from "./pvcvideo.mp4";
import pvcDoorVideo1 from "./pvcvideo1.mp4";

const PvcDoors = () => {
  return (
    <div className="p-3 my-10 lg:p-5 font-urbanist font-semibold border-2 border-no1">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
        {/* First Video on Top */}
        <div className="flex items-start justify-center flex-col">
          <h1 className="heading-custom mt-5 text-no2">
            Watch Our PVC Doors in Action
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our PVC doors in action.
            Watch our video to see how our doors can transform your spaces and
            elevate your interiors and exteriors with style and durability.
          </p>
          <video
            src={pvcDoorVideo1}
            autoPlay
            muted
            loop
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[600px] rounded-xl border-2 border-gray-400 bg-white"
          />
        </div>

        {/* Card 1 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={pvcdoor}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl"
          />
          <h1 className="heading-custom mt-5">
            Elevate Your Doorscapes with FRP and PVC Solutions
          </h1>
          <p className="paragraph-custom">
            Transform your entryways and interior spaces with Mayuri
            Fabrication's premium range of PVC doors and versatile PVC
            solutions. Combining exceptional quality with modern design, our
            products offer unmatched durability, functionality, and style to
            suit all your needs.
          </p>
        </div>

        {/* Card 2 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={pvcdoor1}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            PVC Doors: The Ultimate Choice for Durability and Style
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our PVC doors in action.
            Watch our video to see how our doors can transform your spaces and
            elevate your interiors and exteriors with style and durability.
          </p>
        </div>

        {/* Card 3 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={pvcdoor2}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl"
          />
          <h1 className="heading-custom mt-5">
            Elevate Your Doorscapes with FRP and PVC Solutions
          </h1>
          <p className="paragraph-custom">
            Transform your entryways and interior spaces with Mayuri
            Fabrication's premium range of PVC doors and versatile PVC
            solutions. Combining exceptional quality with modern design, our
            products offer unmatched durability, functionality, and style to
            suit all your needs.
          </p>
        </div>

        {/* Card 4 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={pvcdoor3}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            PVC Doors: The Ultimate Choice for Durability and Style
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our PVC doors in action.
            Watch our video to see how our doors can transform your spaces and
            elevate your interiors and exteriors with style and durability.
          </p>
        </div>

        {/* Card 5 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={pvcdoor4}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl"
          />
          <h1 className="heading-custom mt-5">
            Elevate Your Doorscapes with FRP and PVC Solutions
          </h1>
          <p className="paragraph-custom">
            Transform your entryways and interior spaces with Mayuri
            Fabrication's premium range of PVC doors and versatile PVC
            solutions. Combining exceptional quality with modern design, our
            products offer unmatched durability, functionality, and style to
            suit all your needs.
          </p>
        </div>

        {/* Card 6 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={pvcdoor5}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            PVC Doors: The Ultimate Choice for Durability and Style
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our PVC doors in action.
            Watch our video to see how our doors can transform your spaces and
            elevate your interiors and exteriors with style and durability.
          </p>
        </div>
        <video
          src={pvcDoorVideo}
          autoPlay
          muted
          loop
          className="w-full mt-10 h-auto rounded-xl border-2 border-gray-400 bg-no1"
        />
      </div>
    </div>
  );
};

export default PvcDoors;
