import React from "react";
import { NavLink } from "react-router-dom";
import { FaDownload } from "react-icons/fa";

import company from "./Mayuri Fabs Logo.svg";
import fibredoors from "./fibredoors.pdf";

const Navbar = () => {
  return (
    <div className="flex border-gray-400 border-b z-50 items-center justify-around bg-white font-urbanist flex-col md:flex-row gap-y-5 w-full fixed">
      <div className="flex items-center justify-between w-full md:w-40 border-gray-400 border-b p-4 md:border-none">
        <NavLink to="/">
          <img src={company} alt="company logo" className="h-12" />
        </NavLink>

        <a
          href={fibredoors}
          download="MayuriFabs_Doors.pdf"
          className="bg-no2 flex md:hidden mt-2 text-white w-40 items-center justify-center h-10 rounded-lg text-xs"
        >
          Download Catalog
          <span className="ml-2 animate-bounce">
            <FaDownload />
          </span>
        </a>
      </div>
      <nav className="pb-5 md:pb-0">
        <ul className="flex lg:space-x-6">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                `text-black lg:p-3 lg:px-5 p-2 px-3 rounded-lg text-xs lg:text-base ${
                  isActive
                    ? "bg-no1 h-14 border-gray-400 border font-semibold"
                    : ""
                }`
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about-us"
              className={({ isActive }) =>
                `text-black lg:p-3 lg:px-5 p-2 px-3 rounded-lg text-xs lg:text-base ${
                  isActive
                    ? "bg-no1 h-14 border-gray-400 border font-semibold"
                    : ""
                }`
              }
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/products"
              className={({ isActive }) =>
                `text-black lg:p-3 lg:px-5 p-2 px-3 rounded-lg text-xs lg:text-base ${
                  isActive
                    ? "bg-no1 h-14 border-gray-400 border font-semibold"
                    : ""
                }`
              }
            >
              Products
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/services"
              className={({ isActive }) =>
                `text-black lg:p-3 lg:px-5 p-2 px-3 rounded-lg text-xs lg:text-base ${
                  isActive
                    ? "bg-no1 h-14 border-gray-400 border font-semibold"
                    : ""
                }`
              }
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact-us"
              className={({ isActive }) =>
                `text-black lg:p-3 lg:px-5 p-2 px-3 rounded-lg text-xs lg:text-base ${
                  isActive
                    ? "bg-no1 h-14 border-gray-400 border font-semibold"
                    : ""
                }`
              }
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </nav>
      <a
        href={fibredoors}
        download="MayuriFabs_Doors.pdf"
        className="bg-no2 md:flex hidden text-white w-40 items-center justify-center h-10 rounded-lg text-xs"
      >
        Download Catalog
        <span className="ml-2 animate-bounce">
          <FaDownload />
        </span>
      </a>
    </div>
  );
};

export default Navbar;
