import React, { useState } from "react";

import fibrePots from "./assets/fibrePots.webp";
import fibreDoors from "./assets/fibreDoors.webp";
import modularKitchen from "./assets/modularKitchen.webp";
import upvcDoors from "./assets/upvc.webp";
import pvcDoors from "./assets/PVC.webp";
import manHole from "./assets/manhole.webp";

const productLinks = [
    {
    id: 1,
    title: "Fibre Doors",
    price: "₹ 300 sqft",
    img: fibreDoors,
    description:
      "Modern elegance and long-lasting durability, explore our fiber door collection, designed to enhance the beauty and security of your home.",
  },
  {
    id: 2,
    title: "Modular Kitchen",
    price: "₹ 220 sqft",
    img: modularKitchen,
    description:
      "Our modular kitchens are designed to make the most of your space, with a range of customizable options to suit your style and budget. - Modular Kitchen UPVC: ₹330SQFT, Aluminium: ₹600SQFT.",
  },
   {
    id: 3,
    title: "PVC Doors",
    price: "₹ 2100",
    img: pvcDoors,
    description:
      "Our PVC doors are designed to provide maximum security and durability, with a range of styles to suit your home.",
  },
  {
    id: 4,
    title: "UPVC Doors",
    price: "₹ 500 sqft",
    img: upvcDoors,
    description:
      "Our UPVC doors are designed to provide maximum security and energy efficiency, with a range of styles to suit your home.",
  },
    {
    id: 5,
    title: "Fibre Pots",
    price: "₹ 900",
    img: fibrePots,
    description:
      "Enhance your living space with our weather-resistant fiber pots, perfect for showcasing your greenery both indoors and outdoors.",
  },
  {
    id: 6,
    title: "Manhole Covers",
    price: "₹ 3500",
    img: manHole,
    description:
      "Our manhole covers are designed to provide maximum security and durability, with a range of styles to suit your home.",
  },
];

const ProductDescription = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => setIsExpanded(!isExpanded);

  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + "...";
  };

  return (
    <div className="text-sm text-gray-500 text-left pt-1">
      <p>{isExpanded ? description : truncateText(description, 70)}</p>
      <button
        onClick={toggleReadMore}
        className="text-no1 hover:underline focus:outline-none"
      >
        {isExpanded ? "Read Less" : "Read More"}
      </button>
    </div>
  );
};

export { productLinks, ProductDescription };
