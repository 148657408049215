import fibre_pots from "./Fiber_Pots.png";
import manhole_covers from "./Manhole_covers copy.png";
import modular_kitchens from "./Modular_kitchen.png";
import pvc_doors from "./PVC_doors copy.png";
import upvc_doors from "./UPVC_doors.png";
import fibre_linings from "./Pool_linings.png";

const fibreLinks = [
   {
    name: "Fiber Doors",
    img: upvc_doors,
  },
    {
    name: "PVC Doors",
    img: pvc_doors,
  },
  {
    name: "Modular Kitchens",
    img: modular_kitchens,
  },
  {
    name: "Linings",
    img: fibre_linings,
  },
    {
    name: "Fibre Pots",
    img: fibre_pots,
  },
  {
    name: "Manhole Covers",
    img: manhole_covers,
  },
];

export default fibreLinks;
