import React, { useState } from "react";
import {
  FaTruck,
  FaTools,
  FaClipboardList,
  FaRulerCombined,
  FaRegHandshake,
  FaPencilRuler,
  FaHammer,
  FaWrench,
} from "react-icons/fa";

import doorsStar from "../assets/starsDoors.png";
import servicesfibre from "./assets/services.webp";
import quality from "./assets/1.webp";
import team from "./assets/2.webp";
import support from "./assets/3.webp";
import pricing from "./assets/4.webp";
import Loader from "../Components/Loader/Loader";

const Services = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <div className="p-3 lg:p-5 font-urbanist font-semibold">
        <div className="w-full relative">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white">
              <Loader />
            </div>
          )}
          <img
            src={servicesfibre}
            alt="fibredoors"
            className={`w-full h-auto sm:h-64 md:h-96 xl:h-[600px] rounded-xl border-2 border-gray-400 bg-white ${
              isLoading ? "hidden" : "block"
            }`}
            onLoad={() => {
              setIsLoading(false);
            }}
            onError={() => {
              setIsLoading(false);
            }}
          />
          <h1 className="heading-custom mt-5">Our Comprehensive Services</h1>
          <p className="paragraph-custom">
            At our company, we offer a wide range of services to meet all your
            needs. From installation to maintenance, our team of experts is here
            to ensure that you receive the highest quality service. Explore our
            services to find out how we can help you achieve your goals.
          </p>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mt-5">
            <div className="bg-black text-white rounded-xl border-2 gap-y-5 border-gray-400 p-5 flex text-center items-center flex-col">
              <FaTruck className="text-3xl text-no1 mr-3" />
              <span>Doorstep Delivery</span>
            </div>
            <div className="bg-black text-white rounded-xl border-2 gap-y-5 border-gray-400 p-5 flex items-center text-center flex-col">
              <FaTools className="text-3xl text-no1 mr-3" />
              <span>Quick Installation</span>
            </div>
            <div className="bg-black text-white rounded-xl border-2 gap-y-5 border-gray-400 p-5 flex items-center text-center flex-col">
              <FaClipboardList className="text-3xl text-no1 mr-3" />
              <span>Sampling Demonstration</span>
            </div>
            <div className="bg-black text-white rounded-xl border-2 gap-y-5 border-gray-400 p-5 flex items-center text-center flex-col">
              <FaRulerCombined className="text-3xl text-no1 mr-3" />
              <span>Accurate Measurements</span>
            </div>
          </div>
        </div>
      </div>
      <hr className="w-full h-1 bg-gray-400 mt-5" />
      <div className="p-3 lg:p-5 font-urbanist font-semibold">
        <div className="w-full">
          <img
            src={doorsStar}
            alt="fibredoors"
            className="w-14 h-6 md:w-20 md:h-8"
          />
          <h1 className="heading-custom mt-5">What We Offer</h1>
          <p className="paragraph-custom">
            Discover the wide range of services we provide to meet your needs.
            From custom door solutions to professional installation and
            maintenance, our team is dedicated to delivering exceptional quality
            and service. Explore our offerings to see how we can help enhance
            your home or business.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-5">
          <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
            <h2 className="text-xl font-bold mb-3 flex items-center">
              <FaTruck className="text-2xl text-no1 mr-3" />
              Doorstep Delivery
            </h2>
            <ul className="list-disc list-inside">
              <li>Fast and reliable delivery</li>
              <li>Safe handling of products</li>
              <li>Timely delivery schedules</li>
              <li>Tracking available</li>
            </ul>
          </div>
          <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
            <h2 className="text-xl font-bold mb-3 flex items-center">
              <FaTools className="text-2xl text-no1 mr-3" />
              Quick Installation
            </h2>
            <ul className="list-disc list-inside">
              <li>Professional installation team</li>
              <li>Efficient and timely setup</li>
              <li>Quality assurance</li>
              <li>Post-installation support</li>
            </ul>
          </div>
          <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
            <h2 className="text-xl font-bold mb-3 flex items-center">
              <FaClipboardList className="text-2xl text-no1 mr-3" />
              Sampling Demonstration
            </h2>
            <ul className="list-disc list-inside">
              <li>Product samples available</li>
              <li>In-depth demonstrations</li>
              <li>Expert advice</li>
              <li>Personalized recommendations</li>
            </ul>
          </div>
          <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
            <h2 className="text-xl font-bold mb-3 flex items-center">
              <FaRulerCombined className="text-2xl text-no1 mr-3" />
              Accurate Measurements
            </h2>
            <ul className="list-disc list-inside">
              <li>Precision measurement tools</li>
              <li>Experienced professionals</li>
              <li>Detailed measurement reports</li>
              <li>Ensures perfect fit</li>
            </ul>
          </div>
        </div>
      </div>
      <hr className="w-full h-1 bg-gray-400 mt-5" />

      <div className="p-3 lg:p-5 font-urbanist font-semibold">
        <div className="w-full">
          <h1 className="heading-custom mt-5">Our Process</h1>
          <p className="paragraph-custom">
            Our team follows a detailed process to ensure that you receive the
            best service possible. From initial consultation to final
            installation, we are committed to providing you with a seamless
            experience. Learn more about our process to see how we can help you
            achieve your goals.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-5">
            <div className="bg-black text-white rounded-xl border-2 gap-y-5 border-gray-400 p-5 flex text-center items-center flex-col">
              <FaRegHandshake className="text-3xl text-no1 mr-3" />
              <span>Consultation</span>
              <p className="mt-2 text-sm">
                We start with a thorough consultation to understand your needs
                and preferences.
              </p>
            </div>
            <div className="bg-black text-white rounded-xl border-2 gap-y-5 border-gray-400 p-5 flex items-center text-center flex-col">
              <FaPencilRuler className="text-3xl text-no1 mr-3" />
              <span>Design</span>
              <p className="mt-2 text-sm">
                Our design team creates a customized plan tailored to your
                specifications.
              </p>
            </div>
            <div className="bg-black text-white rounded-xl border-2 gap-y-5 border-gray-400 p-5 flex items-center text-center flex-col">
              <FaHammer className="text-3xl text-no1 mr-3" />
              <span>Installation</span>
              <p className="mt-2 text-sm">
                Professional installation ensures that everything is set up
                correctly and efficiently.
              </p>
            </div>
            <div className="bg-black text-white rounded-xl border-2 gap-y-5 border-gray-400 p-5 flex items-center text-center flex-col">
              <FaWrench className="text-3xl text-no1 mr-3" />
              <span>Maintenance</span>
              <p className="mt-2 text-sm">
                Ongoing maintenance services keep your products in top
                condition.
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr className="w-full h-1 bg-gray-400 mt-5" />

      <div className="p-3 lg:p-5 font-urbanist font-semibold">
        <div className="w-full">
          <h1 className="heading-custom mt-5">Why Choose Us</h1>
          <p className="paragraph-custom">
            With years of experience and a commitment to quality, we are the
            premier choice for all your needs. Our team of experts is dedicated
            to providing you with exceptional service and support. Discover the
            benefits of choosing us for your project.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-5 mt-5 font-urbanist">
            <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
              <img
                src={quality}
                alt="Quality Products"
                className="w-full h-96 rounded-md mb-3"
              />
              <h2 className="text-xl font-bold mb-3">Quality Products</h2>
              <p className="text-sm md:text-base lg:text-xl text-gray-400">
                We offer a wide range of high-quality products designed to meet
                your needs.
              </p>
            </div>
            <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
              <img
                src={team}
                alt="Expert Team"
                className="w-full h-96 rounded-md mb-3"
              />
              <h2 className="text-xl font-bold mb-3">Expert Team</h2>
              <p className="text-sm md:text-base lg:text-xl text-gray-400">
                Our team of professionals has the knowledge and expertise to
                deliver exceptional results.
              </p>
            </div>
            <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
              <img
                src={support}
                alt="Customer Support"
                className="w-full h-96 rounded-md mb-3"
              />
              <h2 className="text-xl font-bold mb-3">Customer Support</h2>
              <p className="text-sm md:text-base lg:text-xl text-gray-400">
                We are committed to providing you with the best customer service
                and support.
              </p>
            </div>
            <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
              <img
                src={pricing}
                alt="Affordable Pricing"
                className="w-full h-96 rounded-md mb-3"
              />
              <h2 className="text-xl font-bold mb-3">Affordable Pricing</h2>
              <p className="text-sm md:text-base lg:text-xl text-gray-400">
                Our competitive pricing ensures that you get the best value for
                your money.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
