import React from "react";
import { NavLink } from "react-router-dom";

import starsDoors from "../../assets/starsDoors.png";

import {
  productLinks,
  ProductDescription,
} from "./Components/ProductDescription";

const ProductsHome = () => {
  return (
    <>
      <div className="p-5">
        <img
          src={starsDoors}
          alt="stars doors"
          className="w-14 h-6 md:w-20 md:h-8"
        />
        <div className="p-3 font-urbanist">
          <p className="text-3xl md:text-4xl lg:text-5xl font-semibold">
            Explore Our Diverse Product Range
          </p>
          <p className="text-sm text-gray-500 pt-2 pl-1 text-left">
            Discover our wide range of products designed to enhance your living
            space: Fibre Pots - perfect for showcasing your greenery both
            indoors and outdoors; Fibre Doors - modern elegance and long-lasting
            durability for your home; Modular Kitchen - customizable options to
            suit your style and budget; UPVC Doors - maximum security and energy
            efficiency; PVC Doors - durable and secure with a range of styles;
            Manhole Covers - secure and durable solutions for your home.
          </p>
        </div>
        <div className="bg-white">
          <div className="grid grid-cols-1 gap-5 p-3 md:grid-cols-2 lg:grid-cols-3 text-left">
            {productLinks.map((product) => (
              <div
                key={product.id}
                className="flex flex-col text-left items-left justify-center p-5 bg-black text-white rounded-lg shadow-lg"
              >
                <img
                  src={product.img}
                  alt={product.title}
                  className="w-full h-full rounded-lg object-center object-cover"
                />
                <p className="text-2xl font-semibold pt-2">{product.title}</p>
                <ProductDescription description={product.description} />
                <div className="flex items-center justify-between pt-5">
                  <p className="text-sm font-semibold text-gray-500">
                    Price starts at <br />
                    <span className="text-white text-base">
                      {product.price}
                    </span>
                  </p>
                  <NavLink
                    to="/products"
                    className="bg-no1 text-black text-sm px-5 md:px-8 lg:px-12 py-3 rounded-lg"
                  >
                    View All Products
                  </NavLink>
                </div>
              </div>
            ))}
          </div>
        </div>
        <hr className="w-full bg-gray-500 h-0.5 rounded-3xl mt-5" />
      </div>
    </>
  );
};

export default ProductsHome;
