import React from "react";
import { NavLink } from "react-router-dom";

import top from "./joinbgTop.webp";
import bottomImg from "./bottomImg.png";

const JoinUs = () => {
  return (
    <>
      <div className="relative pt-10 font-urbanist font-semibold">
        <img
          src={top}
          alt="Top Background"
          className="absolute top-10 left-0 w-96 h-80 opacity-90 lg:w-[80%]"
        />
        <img
          src={bottomImg}
          alt="Bottom Background"
          className="absolute -bottom-40 right-0 w-96 h-96 opacity-90 lg:w-[80%]"
        />
        <hr className="h-0.5 bg-gray-400 w-full mb-5 relative" />
        <div className="flex items-center justify-center flex-col lg:flex-row lg:mt-20 lg:px-60">
          <div className="relative mt-20 lg:mt-5 flex items-left flex-col justify-center p-5">
            <p className="text-2xl md:text-4xl lg:text-5xl font-semibold py-5">
              Don't wait! Take action today!
            </p>
            <p className="text-sm md:text-base lg:text-xl font-light text-gray-500 lg:w-[80%]">
              Explore our website to discover the complete range of Mayuri
              Fabrication fiber solutions. Get a free quote for your desired
              products and installation services (if applicable).
            </p>
          </div>
          <div className="relative mt-5 flex items-center justify-center flex-col lg:flex-row w-full p-5 gap-5">
            <a href="tel:+919952338221" className="w-full">
              <button className="border border-gray-300 p-4 rounded-xl bg-no1 text-black w-full">
                Call Us Today!
              </button>
            </a>
            <NavLink
              to="/products"
              className="border border-gray-300 p-4 rounded-xl bg-no2 text-center text-white w-full"
            >
              Explore Products
            </NavLink>
          </div>
        </div>
      </div>
      <hr className="h-0.5 bg-gray-400 w-full mt-40" />
    </>
  );
};

export default JoinUs;
