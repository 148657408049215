import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaWhatsapp,
} from "react-icons/fa";

import { BsTwitterX } from "react-icons/bs";

import MapsModal from "../Components/Models/Maps/Maps";
import FaqsModal from "../Components/Models/FAQS/Faqs";
import Testimonials from "../Components/Models/Testimonials/Testimonials";
import PrivacyPolicy from "../Components/Models/PrivacyPolicy/PrivacyPolicy";
import OurTerms from "../Components/Models/OurTerms/OurTerms";
import JoinUs from "../JoinUs/JoinUs";
import logo from "./Mayuri Fabs Logo.svg";

import ContactForm from "../Mails/Letters";

const Footer = () => {
  const mapsModalRef = useRef();
  const faqsModalRef = useRef();
  const testimonialsModalRef = useRef();
  const privacyPolicyModalRef = useRef();
  const ourTermsModalRef = useRef();

  const openStoreMap = () => {
    if (mapsModalRef.current) {
      mapsModalRef.current.openModal(
        "https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d31335.95615888963!2d76.92971465914192!3d10.96378570856587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d10.9399825!2d76.9484446!4m5!1s0x3ba859864e943a29%3A0xf39588182b2768ca!2smayurifabs!3m2!1d10.987882599999999!2d76.9462612!5e0!3m2!1sen!2sin",
        "Store"
      );
    }
  };

  const openFaqs = () => {
    if (faqsModalRef.current) {
      faqsModalRef.current.openModal();
    }
  };

  const openTestimonials = () => {
    if (testimonialsModalRef.current) {
      testimonialsModalRef.current.openModal();
    }
  };

  const openPrivacyPolicy = () => {
    if (privacyPolicyModalRef.current) {
      privacyPolicyModalRef.current.openModal();
    }
  };

  const openOurTerms = () => {
    if (ourTermsModalRef.current) {
      ourTermsModalRef.current.openModal();
    }
  };

  return (
    <>
      <JoinUs />
      <div className="w-full bottom-0 z-20">
        <div className="bg-black font-urbanist font-semibold text-sm md:text-base flex items-center text-gray-300 justify-around flex-col lg:flex-row w-full lg:px-10 lg:py-20 text-center">
          <div className="flex flex-col md:flex-row items-center md:justify-around md:w-full lg:pb-10">
            <div className="flex-col flex items-center justify-center lg:items-start text-center pt-10">
              <img src={logo} alt="Mayuri Fabs Logo" className="w-60 h-30" />
              <p className="p-5 lg:p-0 lg:my-5">
                Let's transform your space together. <br /> Get a quote
                delivered to your inbox.
              </p>
              {/* <input
                type="email"
                placeholder="Enter your email"
                className="px-24 py-3 lg:px-8 rounded-xl border border-gray-300 bg-gray-100"
              /> */}
              <ContactForm />
            </div>
            <div className="flex-col items-center text-left justify-center md:pt-16 pt-5">
              <NavLink to="/contact-us" className="py-3 text-no2 underline">
                Contact Us
              </NavLink>
              <ul className="gap-y-3 flex flex-col md:mt-5">
                <li>
                  Phone:{" "}
                  <a href="tel:+919952338221" className="text-no1">
                    +91 99523 38221
                  </a>
                </li>
                <li>
                  Email:{" "}
                  <a href="mailto:mayurifabs@gmail.com" className="text-no1">
                    mayurifabs@gmail.com
                  </a>
                </li>
                <li>
                  Address:{" "}
                  <a
                    href="https://maps.app.goo.gl/nnzkL4bu44kBPWfT7"
                    className="text-no1"
                  >
                    2, Sundakamuthur Road, <br /> Ukaddam Bypass, Coimbatore,
                    <br />
                    Tamil Nadu 641001
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex items-center justify-around w-full pb-10 lg:pb-0">
            {" "}
            <div className="flex-col items-center text-left justify-center lg:items-start pt-5 lg:pt-7">
              <p className="py-3 text-no2 underline">About us</p>
              <ul className="gap-y-2 flex flex-col">
                <li onClick={openStoreMap} className="cursor-pointer">
                  Store Location
                </li>
                <li onClick={openFaqs} className="cursor-pointer">
                  FAQS
                </li>
                <li onClick={openTestimonials} className="cursor-pointer">
                  Testimonials
                </li>
                <li onClick={openPrivacyPolicy} className="cursor-pointer">
                  Privacy Policy
                </li>
                <li onClick={openOurTerms} className="cursor-pointer">
                  Our Terms
                </li>
              </ul>
              <MapsModal ref={mapsModalRef} />
              <FaqsModal ref={faqsModalRef} />
              <Testimonials ref={testimonialsModalRef} />
              <PrivacyPolicy ref={privacyPolicyModalRef} />
              <OurTerms ref={ourTermsModalRef} />
            </div>
            <div className="flex-col items-center justify-center text-left lg:items-start pt-5 lg:pt-7">
              <NavLink to="/products" className="text-no2 underline">
                Products
              </NavLink>
              <NavLink to="/products" className="text-no1">
                <ul className="gap-y-2 flex flex-col pt-3">
                  <li>Fibre Pots</li>
                  <li>Fibre Doors</li>
                  <li>Modular Kitchen</li>
                  <li>Manhole Covers</li>
                  <li>Roofing Sheets</li>
                </ul>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="bg-no1 w-full">
          <div className="flex items-center justify-around flex-col-reverse md:flex-row md:px-5 lg:py-5">
            <div className="flex text-center items-center md:flex-row md:px-5 flex-col text-black font-urbanist font-semibold py-5 lg:gap-x-5">
              {" "}
              <p className="py-1">© 2024 Mayuri Fabs. All rights Reserved.</p>
              <p>
                Site crafted by{" "}
                <a href="https://ansaribrahim.me/" className="text-red-500">
                  Tech Trove Inc.
                </a>
              </p>
            </div>
            <div className="flex items-center justify-center space-x-4 pt-5 md:pt-0">
              <a
                href="https://wa.me/919952338221"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black p-5 rounded-full text-white"
              >
                <FaWhatsapp />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61564081797550"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black p-5 rounded-full text-white"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.instagram.com/mayurifabscbe/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black p-5 rounded-full text-white"
              >
                <FaInstagram />
              </a>
              <a
                href="https://x.com/mayurifabs"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black p-5 rounded-full text-white"
              >
                <BsTwitterX />
              </a>
              <a
                href="https://www.youtube.com/channel/UCKrs8ckkj7z20F56thA3mvQ"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black p-5 rounded-full text-white"
              >
                <FaYoutube />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
