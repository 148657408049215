import React, { useState } from "react";

import contact from "./assets/contact.webp";
import mail from "./assets/mail.png";
import phone from "./assets/phone.png";
import address from "./assets/address.png";
import stardoor from "../assets/starsDoors.png";

import fibreBig from "./assets/fibreBig.webp";
import fibredesktop from "./assets/fibredesktop.webp";
import fibreSmall from "./assets/fibreSmall.webp";
import fibreProducts from "./assets/fibreProducts.webp";
import Loader from "../Components/Loader/Loader";

import Inquiry from "../Mails/Inquiry";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      <div className="p-3 lg:p-5 font-urbanist font-semibold">
        <div className="w-full relative">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white">
              <Loader />
            </div>
          )}
          <img
            src={contact}
            alt="fibredoors"
            className={`w-full h-auto sm:h-64 md:h-96 xl:h-[600px] rounded-xl border-2 border-gray-400 bg-white ${
              isLoading ? "hidden" : "block"
            }`}
            onLoad={() => {
              setIsLoading(false);
            }}
            onError={() => {
              setIsLoading(false);
            }}
          />
        </div>
        <div className="w-full p-3 lg:p-5 pt-10 lg:pt-10">
          <h1 className="heading-custom">Get In Touch With Us!</h1>
          <p className="paragraph-custom">
            Welcome to Mayuri Fabs Contact Us Page. We are here to help you with
            any queries you may have. Please feel free to contact us. We will be
            happy to help you. We are available 24/7. You can contact us by any
            of the following methods. We are here to help you.
          </p>
        </div>
      </div>
      <hr className="w-full h-1 bg-gray-500" />
      <div className="flex items-center justify-center flex-col mt-5 p-2 w-full gap-5">
        <div className="border border-gray-500 w-full p-5 gap-2 lg:gap-5 flex-col flex rounded-3xl">
          {" "}
          <div className="flex items-center justify-between w-full gap-x-1 lg:gap-x-5">
            {" "}
            <a
              href="mailto:mayurifabsdm@gmail.com"
              className="w-full cursor-pointer"
            >
              <div className="bg-black text-white w-full gap-y-5 rounded-xl border-2 border-gray-400 py-8 p-5 flex flex-col items-center justify-center transition-transform transform hover:scale-105 hover:bg-gray-800 hover:shadow-lg md:active:scale-95 md:active:bg-gray-700">
                <img src={mail} alt="mayuri_email" className="w-16 h-16" />
                <p className="text-white text-xs md:text-sm lg:text-base">
                  mayurifabsdm@gmail.com
                </p>
              </div>
            </a>
            <a href="tel:+919952338221" className="w-full">
              <div className="bg-black text-white w-full gap-y-5 rounded-xl border-2 border-gray-400 py-8 p-5 flex flex-col items-center justify-center transition-transform transform hover:scale-105 hover:bg-gray-800 hover:shadow-lg md:active:scale-95 md:active:bg-gray-700">
                <img src={phone} alt="mayuri_phone" className="w-16 h-16" />
                <p className="text-white text-xs md:text-sm lg:text-base">
                  +91 9952338221
                </p>
              </div>
            </a>
          </div>
          <a
            href="https://maps.app.goo.gl/nnzkL4bu44kBPWfT7"
            className="w-full"
          >
            <div className="bg-black text-white w-full gap-y-5 rounded-xl border-2 border-gray-400 py-8 p-5 flex flex-col items-center justify-center transition-transform transform hover:scale-105 hover:bg-gray-800 hover:shadow-lg md:active:scale-95 md:active:bg-gray-700 text-center">
              <img src={address} alt="mayuri_address" className="w-16 h-16" />
              <p className="text-white text-xs md:text-sm lg:text-base">
                2, Sundakamuthur Road, Ukaddam Bypass, <br /> Coimbatore, Tamil
                Nadu 641001
              </p>
            </div>
          </a>
        </div>
      </div>
      <div className="p-3 lg:p-5 font-urbanist font-semibold">
        <div className="w-full">
          <img
            src={stardoor}
            alt="fibredoors"
            className="w-14 h-6 md:w-20 md:h-8"
          />
          <h1 className="heading-custom mt-3">Let's Connect</h1>
          <p className="paragraph-custom">
            We're here to assist you at any time. Feel free to reach out to us
            through any of the following methods. Our team is available around
            the clock to provide support and answer your queries. Your
            satisfaction is our priority.
          </p>
        </div>
        <div className="">
          <div className="flex border border-gray-500 rounded-3xl w-full p-3 flex-col items-center justify-center lg:flex-row">
            <div className="flex items-start justify-start flex-col w-full mt-3 lg:mt-0 font-urbanist lg:p-10">
              <img
                src={stardoor}
                alt="fibrestars"
                className="w-14 h-6 md:w-20 md:h-8"
              />
              <h1 className="heading-custom mt-3">Our Location</h1>
              <p className="paragraph-custom">
                We are located in the heart of the city, making it convenient
                for you to visit us. Our office is easily accessible by public
                transportation and has ample parking space. We look forward to
                welcoming you to our location and assisting you with any
                inquiries you may have.
              </p>
              <div className="flex flex-col items-center justify-center gap-y-5">
                <div className="flex flex-col items-start justify-start gap-y-2 text-sm rounded-2xl bg-black p-5">
                  <h1 className="text-gray-400">Main Store</h1>
                  <p className="text-white">
                    2, Sundakamuthur Road, Ukaddam Bypass, Coimbatore, Tamil
                    Nadu 641001
                  </p>
                  <p className="text-gray-400">
                    Our main store offers a wide range of products and services
                    to meet all your needs. We pride ourselves on providing
                    excellent customer service and high-quality products. Visit
                    us to explore our latest collections and take advantage of
                    our special offers.
                  </p>
                  <div className="w-full flex items-center justify-center mt-4">
                    <a
                      href="https://maps.app.goo.gl/nnzkL4bu44kBPWfT7"
                      className="w-full bg-no2 text-white p-2 py-5 text-base rounded-xl text-center"
                    >
                      Get Directions
                    </a>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-y-2 text-sm rounded-2xl bg-black p-5">
                  <h1 className="text-gray-400">Manufacture Unit</h1>
                  <p className="text-white">
                    21A, Palaniyappa Nagar, Perur Bypass Ukkaddam, Coimbatore 641001
                  </p>
                  <p className="text-gray-400">
                    Our manufacturing unit is equipped with state-of-the-art
                    machinery and technology to ensure the highest quality of
                    production. We adhere to strict quality control measures and
                    industry standards to deliver products that meet your
                    expectations. Visit our unit to see our manufacturing
                    process in action and learn more about our commitment to
                    excellence.
                  </p>
                  <div className="w-full flex items-center justify-center mt-4">
                    <a
                      href="https://maps.app.goo.gl/qe1HznpQFK7KE2Af7"
                      className="w-full bg-no2 text-white p-2 py-5 text-base rounded-xl text-center"
                    >
                      Get Directions
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <Inquiry />
          </div>
        </div>
      </div>
      <div className="bg-black mt-5 rounded-2xl">
        <div className="flex flex-col items-center justify-center p-5 md:px-8">
          <img
            src={fibreProducts}
            alt="fibre big"
            className="w-full h-full md:hidden"
          />
          <img
            src={fibredesktop}
            alt="fibre big"
            className="hidden md:flex w-full h-auto"
          />
        </div>
        <img
          src={stardoor}
          alt="stars doors"
          className="w-14 h-6 md:w-20 md:h-8 pl-2 md:pl-5 mt-2"
        />
        <div className="font-urbanist text-white p-5 lg:p-10 flex items-center justify-between">
          <div className="flex flex-col items-start justify-center">
            <p className="text-2xl md:text-4xl lg:text-5xl font-semibold pb-5">
              Imagine the Possibilities: Transform Your Space with Mayuri
              Fabrication
            </p>
            <p className="text-sm md:text-base lg:text-xl font-light text-gray-500 pt-1 lg:w-[80%]">
              Browse our gallery showcasing captivating images of Mayuri
              Fabrication products creating stunning spaces. Imagine your dream
              kitchen featuring our sleek and durable fiber cabinets and
              countertops, perfectly complementing the open floor plan and
              creating a light and airy feel.
            </p>
            <img
              src={fibreSmall}
              alt="fibre big"
              className="w-full h-auto mt-5 lg:hidden"
            />
          </div>
          <img
            src={fibreBig}
            alt="fibre big"
            className="w-full h-auto mt-5 hidden lg:flex"
          />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
