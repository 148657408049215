import React from "react";

import starsDoors from "../../assets/starsDoors.png";
import fibreSheets from "./assets/fibresheets.webp";
import fibreBig from "./assets/fibreBig.webp";
import fibredesktop from "./assets/fibredesktop.webp";
import fibreSmall from "./assets/fibreSmall.webp";
import fibreProducts from "./assets/fibreProducts.webp";
import Counts from "../../Components/Counts/Counts";

const WhyUs = () => {
  return (
    <div className="p-5 font-urbanist">
      <img
        src={starsDoors}
        alt="stars doors"
        className="w-14 h-6 md:w-20 md:h-8"
      />
      <div className="flex items-center justify-between flex-col-reverse lg:flex-row w-full">
        <div className="flex items-left justify-center flex-col lg:w-[50%] pt-5 lg:px-5">
          <p className="text-2xl md:text-3xl lg:text-4xl font-semibold px-1">
            Why Choose Mayuri Fabrication for Your Fiber Solutions?
          </p>
          <p className="text-sm md:text-base lg:text-xl font-light text-gray-500 py-4 px-1">
            Not all fiber products are created equal. At Mayuri Fabrication, we
            prioritize quality by using premium materials for long-lasting
            durability. Our innovative approach ensures that we are constantly
            developing new and improved fiber solutions for your home. We
            understand the needs of South Indian homeowners and design products
            that complement the local climate and aesthetics. Choose Mayuri
            Fabrication for lasting beauty, cutting-edge design, and a perfect
            fit for your South Indian space.
          </p>
          <Counts bgColor="bg-no2" />
        </div>
        <div className="flex items-center justify-center pt-5">
          <img
            src={fibreSheets}
            alt="fibre sheets"
            className="w-full h-auto border border-gray-600 rounded-3xl"
          />
        </div>
      </div>
      <div className="bg-black mt-5 rounded-2xl">
        <div className="flex flex-col items-center justify-center p-5 md:px-8">
          <img
            src={fibreProducts}
            alt="fibre big"
            className="w-full h-full md:hidden"
          />
          <img
            src={fibredesktop}
            alt="fibre big"
            className="hidden md:flex w-full h-auto"
          />
        </div>
        <img
          src={starsDoors}
          alt="stars doors"
          className="w-14 h-6 md:w-20 md:h-8 pl-2 md:pl-5 mt-2"
        />
        <div className="font-urbanist text-white p-5 lg:p-10 flex items-center justify-between">
          <div className="flex flex-col items-start justify-center">
            <p className="text-2xl md:text-4xl lg:text-5xl font-semibold pb-5">
              Imagine the Possibilities: Transform Your Space with Mayuri
              Fabrication
            </p>
            <p className="text-sm md:text-base lg:text-xl font-light text-gray-500 pt-1 lg:w-[80%]">
              Browse our gallery showcasing captivating images of Mayuri
              Fabrication products creating stunning spaces. Imagine your dream
              kitchen featuring our sleek and durable fiber cabinets and
              countertops, perfectly complementing the open floor plan and
              creating a light and airy feel.
            </p>
            <img
              src={fibreSmall}
              alt="fibre big"
              className="w-full h-auto mt-5 lg:hidden"
            />
          </div>
          <img
            src={fibreBig}
            alt="fibre big"
            className="w-full h-auto mt-5 hidden lg:flex"
          />
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
