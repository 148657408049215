import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaTags } from "react-icons/fa";

import aboutus from "./assets/Aboutus.webp";
import starsDoors from "../assets/starsDoors.png";
import {
  missionAboutus,
  mayuriAboutus,
  customersAboutus,
  ourTeam,
  clientsAboutus,
} from "./Components/CustomersAboutus.js";
import Loader from "../Components/Loader/Loader.jsx";

const AboutUs = () => {
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setSlidesToShow(3);
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerPadding: "20px",
  };

  return (
    <>
      <div className="p-3 lg:p-5 font-urbanist font-semibold">
        <div className="w-full relative">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white">
              <Loader />
            </div>
          )}
          <img
            src={aboutus}
            alt="fibredoors"
            className={`w-full h-auto sm:h-64 md:h-96 xl:h-[600px] rounded-xl border-2 border-gray-400 bg-white ${
              isLoading ? "hidden" : "block"
            }`}
            onLoad={() => {
              setIsLoading(false);
            }}
            onError={() => {
              setIsLoading(false);
            }}
          />
        </div>
        <div className="my-5">
          {mayuriAboutus.slice(0, 3).map((item) => (
            <div
              key={item.id}
              className="my-10 border border-gray-500 p-5 rounded-3xl shadow-lg"
            >
              <img
                src={starsDoors}
                alt="stars doors"
                className="w-14 h-6 md:w-20 md:h-8 mt-2"
              />
              <div className="p-5">
                <h2 className="heading-custom">{item.title}</h2>
                <p className="paragraph-custom">{item.description}</p>
                <div className="flex flex-wrap">
                  {item.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="bg-gray-200 text-gray-700 rounded-full px-2 py-1 text-xs mr-2 mt-2"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {customersAboutus.map((item) => (
              <div key={item.id} className="mb-4 bg-black p-10 rounded-2xl">
                <h2 className="heading-custom text-white">{item.title}</h2>
                <p className="paragraph-custom">{item.description}</p>
                <div className="flex flex-wrap">
                  {item.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="bg-gray-200 text-gray-700 rounded-full px-4 py-1 text-xs mr-2 mt-2"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="border border-gray-500 rounded-3xl shadow-lg p-5 mt-5">
            {mayuriAboutus.slice(3, 4).map((item) => (
              <div key={item.id} className="p-5">
                <img
                  src={starsDoors}
                  alt="stars doors"
                  className="w-14 h-6 md:w-20 md:h-8"
                />
                <h2 className="heading-custom mt-3">{item.title}</h2>
                <p className="paragraph-custom">{item.description}</p>
                <div className="flex flex-wrap">
                  {item.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="bg-gray-200 text-gray-700 rounded-full px-2 py-1 text-xs mr-2 mt-2"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            ))}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-5">
              {missionAboutus.map((item) => (
                <div
                  key={item.id}
                  className="mb-4 bg-no1 p-10 text-black rounded-2xl border border-gray-300 shadow-md"
                >
                  <div className="flex justify-center items-center bg-black rounded-full w-16 h-16">
                    <item.icon className="text-3xl text-white" />
                  </div>
                  <h2 className="heading-custom mt-2">{item.title}</h2>
                  <p className="paragraph-custom">{item.description}</p>
                </div>
              ))}
            </div>
          </div>

          {mayuriAboutus.slice(4, 5).map((item) => (
            <div
              key={item.id}
              className="my-10 border border-gray-500 p-5 rounded-3xl shadow-lg"
            >
              <img
                src={starsDoors}
                alt="stars doors"
                className="w-14 h-6 md:w-20 md:h-8 mt-2"
              />
              <div className="p-5">
                <h2 className="heading-custom">{item.title}</h2>
                <p className="paragraph-custom">{item.description}</p>
                <div className="flex flex-wrap">
                  {item.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="bg-gray-200 text-gray-700 rounded-full px-2 py-1 text-xs mr-2 mt-2"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
          <div className="border border-gray-500 p-5 rounded-3xl shadow-lg">
            {mayuriAboutus.slice(5).map((item) => (
              <div key={item.id} className="p-5">
                <img
                  src={starsDoors}
                  alt="stars doors"
                  className="w-14 h-6 md:w-20 md:h-8"
                />
                <h2 className="heading-custom mt-3">{item.title}</h2>
                <p className="paragraph-custom">{item.description}</p>
                <div className="flex flex-wrap">
                  {item.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="bg-gray-200 text-gray-700 rounded-full px-2 py-1 text-xs mr-2 mt-2"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            ))}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 font-urbanist mt-5">
              {ourTeam.map((item) => (
                <div
                  key={item.id}
                  className="flex flex-col items-center border-2 border-gray-300 p-5 rounded-3xl shadow-lg"
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-full h-full rounded-3xl"
                  />
                  <h2 className="heading-custom mt-3">{item.name}</h2>
                  <p className="paragraph-custom">{item.position}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full my-10">
          <div className="">
            <img
              src={starsDoors}
              alt="stars doors"
              className="w-14 h-6 md:w-20 md:h-8 mt-2"
            />
            <p className="heading-custom mt-4">Our Valued Clients</p>
            <p className="paragraph-custom">
              Discover the real-life impact of our PVC doors through the
              testimonials of our satisfied customers. From durability and style
              to energy efficiency and low maintenance, hear firsthand why
              homeowners choose us.
            </p>
          </div>
          <Slider {...settings}>
            {clientsAboutus.map((client) => (
              <div key={client.id} className="p-2">
                <div className="flex flex-col justify-between h-full md:min-h-[220px] items-start font-urbanist font-semibold w-full py-10 bg-no1 text-black p-5 rounded-lg shadow-lg gap-y-5">
                  <p className="text-lg font-semibold text-gray-400">
                    {client.time}
                  </p>
                  <p className="heading-custom">{client.name}</p>
                  <a
                    href={client.link}
                    className="btn bg-black text-gray-400 w-full text-center py-6 rounded-2xl text-sm md:text-xl"
                  >
                    Visit {client.name}
                  </a>
                  <p className="text-sm md:text-xl flex items-center">
                    <FaTags className="mr-2" />
                    Category:{" "}
                    <span className="paragraph-custom">{client.category}</span>
                  </p>
                  <div className="border-2 p-5 border-gray-400 rounded-2xl">
                    <p className="paragraph-custom">What They Said 🤗</p>
                    <p className="text-sm md:text-xl">{client.description}</p>
                  </div>{" "}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
