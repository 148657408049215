import React from "react";
import { NavLink } from "react-router-dom";

import { doors, doors_img } from "./assets";
import Counts from "../Components/Counts/Counts";
import fibreLinks from "./Components/fibreLinks";
import ProductsHome from "./Products/ProductsHome";
import WhyUs from "./WhyUs/WhyUs";
import Customers from "./Customers/Customers";
import Questions from "./Questions/Questions";

const Home = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center w-full">
        <div className="flex flex-col items-center justify-center lg:flex-row-reverse w-full font-urbanist">
          <div className="relative p-5 lg:p-0">
            <img
              src={doors}
              alt="doors"
              className="w-full h-auto rounded-2xl lg:rounded-none border-gray-600 border shadow-lg lg:shadow-none lg:border-none"
            />
            <img
              src={doors_img}
              alt="upvc doors"
              className="absolute -bottom-8 md:-bottom-10 left-5 h-32 md:h-40 lg:top-20 lg:-left-20 animate-spin-slow"
            />
          </div>
          <div className="flex flex-col items-center justify-center pt-10 gap-y-2 lg:gap-y-4 2xl:gap-y-6 md:pt-14 px-5 font-urbanist text-left lg:w-[60%]">
            <p className="text-3xl md:text-4xl lg:text-5xl font-semibold py-2 w-full lg:w-[92%] lg:mr-7">
              Mayuri Fabrication: Leading the Way in Fiber Innovation
            </p>
            <p className="text-lg text-gray-600 font-semibold md:text-2xl lg:text-3xl w-full pb-1 lg:w-[95%]">
              Empowering Our Clients Since 2013.
            </p>
            <p className="text-base font-light md:text-lg lg:text-2xl lg:w-[95%]">
              Committed to quality and exceeding expectations, Mayuri
              Fabrication, based in Coimbatore, Tamil Nadu, has established
              itself as a leader in fiber-reinforced plastic solutions. We
              specialize in doors, modular kitchens, UPVC doors, PVC doors,
              fibre pots, and manhole covers.
            </p>
            <div className="flex text-center flex-col w-full lg:flex-row pt-5 gap-y-5 font-urbanist font-semibold text-lg md:gap-x-10 lg:w-[95%]">
              <NavLink
                to="/contact-us"
                className="border border-gray-300 p-4 rounded-xl text-sm md:text-base lg:text-lg"
              >
                Contact Us
              </NavLink>
              <NavLink
                to="/products"
                className="border border-gray-300 p-4 rounded-xl bg-no2 text-white text-sm md:text-base lg:text-lg"
              >
                View Products
              </NavLink>
            </div>
            <Counts bgColor="bg-black" />
          </div>
        </div>
        <div className="pt-5 border-8 border-no1 w-full p-5 mt-5">
          <NavLink to="/products">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-5 md:gap-10 lg:gap-10 2xl:gap-28">
              {fibreLinks.map((link, index) => (
                <div
                  key={index}
                  className="flex flex-col text-center items-center bg-no2 justify-center gap-y-2 rounded-2xl font-urbanist p-7"
                >
                  <img
                    src={link.img}
                    alt={link.name}
                    className="w-20 h-20 rounded-full"
                  />
                  <p className="text-sm text-white font-semibold">
                    {link.name}
                  </p>
                </div>
              ))}
            </div>
          </NavLink>
        </div>
      </div>
      <ProductsHome />
      <WhyUs />
      <Customers />
      <Questions />
    </>
  );
};

export default Home;
