import { FaHandshake, FaUsers, FaGlobe, FaHeart } from "react-icons/fa";

import Navas from "../assets/Navas.webp";
import Nazeer from "../assets/Nazir.webp";

const missionAboutus = [
  {
    id: 1,
    icon: FaHandshake,
    title: "Quality",
    description:
      "We are committed to delivering products that meet the highest standards of quality and durability.",
  },
  {
    id: 2,
    icon: FaUsers,
    title: "Innovation",
    description: "We strive to innovate and improve our products continuously.",
  },
  {
    id: 3,
    icon: FaGlobe,
    title: "Sustainability",
    description:
      "We are dedicated to sustainable practices that protect our planet.",
  },
  {
    id: 4,
    icon: FaHeart,
    title: "Customer Care",
    description:
      "We prioritize our customers and aim to provide exceptional service.",
  },
];

const mayuriAboutus = [
  {
    id: 1,
    title: "Mayuri Fabrication: Crafting Excellence in Fiber Solutions",
    description:
      "Mayuri Fabrication is a pioneer in the design and manufacturing of innovative fiber-reinforced plastic (FRP) products. With a steadfast commitment to quality and customer satisfaction, we have established ourselves as a leading name in the industry. Our products are known for their durability, strength, and aesthetic appeal.",
    tags: ["FRP Products", "Quality", "Customer Satisfaction"],
  },
  {
    id: 2,
    title: "Our Achievements",
    description:
      "We take pride in our achievements, which include the successful completion of numerous projects for residential, commercial, and industrial clients. Our dedication to excellence has earned us a reputation for delivering high-quality products that meet the unique needs of each customer. Our portfolio showcases a diverse range of projects that highlight our expertise and commitment.",
    tags: ["Achievements", "High-Quality Products", "Diverse Projects"],
  },
  {
    id: 3,
    title: "Our Story",
    description:
      "Mayuri Fabrication was founded with a vision to provide innovative fiber solutions that enhance the functionality and aesthetics of living spaces. Over the years, we have grown to become a trusted name in the industry, known for our commitment to quality and customer satisfaction. Our journey is marked by continuous improvement and a passion for excellence.",
    tags: ["Company History", "Innovation", "Quality"],
  },
  {
    id: 4,
    title: "Our Mission",
    description:
      "Our mission is to provide top-notch fiber solutions that meet the diverse needs of our customers. We strive to deliver products that are durable, aesthetically pleasing, and affordable, ensuring that our customers are satisfied with their purchase. We are dedicated to maintaining the highest standards of quality in everything we do.",
    tags: ["Mission", "Durability", "Affordability"],
  },
  {
    id: 5,
    title: "Our Vision",
    description:
      "Our vision is to be the leading provider of fiber-reinforced plastic solutions, recognized for our innovation, quality, and customer-centric approach. We aim to continuously evolve and adapt to the changing needs of the market, setting new benchmarks in the industry.",
    tags: ["Vision", "Innovation", "Customer-Centric"],
  },
  {
    id: 6,
    title: "Our Team",
    description:
      "Our team comprises highly skilled professionals who are passionate about delivering the best fiber solutions. With extensive experience and expertise, our team works collaboratively to ensure that every project is executed to perfection. We believe that our people are our greatest asset.",
    tags: ["Team", "Expertise", "Collaboration"],
  },
];

const customersAboutus = [
  {
    id: 1,
    title: "200+ Happy Customers",
    description:
      "We have a growing list of satisfied customers who have trusted us with their homes and businesses. Our commitment to quality and customer satisfaction has earned us a loyal customer base. We take pride in our ability to deliver exceptional service and high-quality products that meet the unique needs of each customer.",
    tags: ["Quality Service", "Customer Satisfaction", "Trustworthy"],
  },
  {
    id: 2,
    title: "100% Satisfaction Guarantee",
    description:
      "We are committed to providing the best quality products and services to our customers. Our satisfaction guarantee ensures that you will be happy with your purchase. If for any reason you are not satisfied, we will work with you to make it right. Your happiness is our top priority.",
    tags: ["Quality Products", "Satisfaction Guarantee", "Reliable"],
  },
  {
    id: 4,
    title: "Professional Installation",
    description:
      "Our team of experts will ensure that your products are installed correctly and efficiently. We take pride in our professional installation services, ensuring that every detail is perfect. Our installers are highly trained and experienced, guaranteeing a seamless and hassle-free installation process.",
    tags: ["Professional Installation", "Expert Team", "Efficient Service"],
  },
  {
    id: 5,
    title: "10k+ Products Sold",
    description:
      "We have sold over 10,000 products to customers across the country. Our extensive range includes fibre doors, PVC doors, and more, all designed to meet your specific needs. Our products are known for their durability, aesthetic appeal, and affordability, making them a popular choice among homeowners and businesses alike.",
    tags: ["Fibre Door", "PVC Door", "Wide Range"],
  },
  {
    id: 6,
    title: "5 Star Ratings",
    description:
      "Our customers have given us 5-star ratings for our products and services. We strive to maintain the highest standards of quality and customer satisfaction. Our commitment to excellence is reflected in the positive feedback we receive from our customers, who appreciate our attention to detail and dedication to their needs.",
    tags: ["5 Star Ratings", "High Quality", "Customer Satisfaction"],
  },
  {
    id: 7,
    title: "7+ Years of Experience",
    description:
      "We have been in the business for over 7 years and have the expertise to meet all your needs. Our experience in the industry ensures that we provide top-notch products and services. We stay up-to-date with the latest trends and technologies to offer innovative solutions that enhance the functionality and beauty of your space.",
    tags: ["Experienced", "Industry Expertise", "Reliable Service"],
  },
];

const ourTeam = [
  {
    id: 1,
    name: "Nazeer",
    position: "CEO",
    image: Nazeer,
  },
  {
    id: 2,
    name: "Navaz",
    position: "Manager",
    image: Navas,
  },
];

const clientsAboutus = [
  {
    id: 1,
    time: "Since 1987",
    name: "Sreenivasan Asscociate Pvt",
    link: "https://sapl.in/",
    description:
      "Mayurifabs has consistently delivered high-quality construction materials that meet our stringent standards. Their reliability and professionalism are unmatched.",
    category: "Engineers & Builders",
  },
  {
    id: 2,
    time: "Since 1992",
    name: "RR Housing",
    link: "https://www.rrhousing.net/",
    description:
      "Working with Mayurifabs has been a pleasure. Their commitment to quality and timely delivery has greatly contributed to the success of our housing projects.",
    category: "Real Estate",
  },
  {
    id: 3,
    time: "Since 2015",
    name: "Sri Ram Constructions",
    link: "https://www.sriramassociates.in/",
    description:
      "Mayurifabs is our go-to supplier for all our construction needs. Their products are top-notch, and their customer service is exceptional.",
    category: "Construction",
  },
  {
    id: 4,
    time: "Since 2015",
    name: "Marudhum Groups cbe",
    link: "https://www.maruthamgroup.com/ongoing-projects-coimbatore",
    description:
      "We have been partnering with Mayurifabs for years, and they have never let us down. Their dedication to excellence is evident in every interaction.",
    category: "Real Estate",
  },
  {
    id: 5,
    time: "Since 1992",
    name: "Ramanini PVT cbe",
    link: "https://www.ramanirealtors.com/",
    description:
      "Mayurifabs provides innovative solutions that have helped us stay ahead in the competitive construction industry. Their team is knowledgeable and always ready to assist.",
    category: "Real Estate",
  },
  {
    id: 6,
    time: "Since 2008",
    name: "Aditiya Institute",
    link: "https://adithyatech.edu.in/",
    description:
      "The quality of materials supplied by Mayurifabs has been instrumental in the development of our state-of-the-art facilities. We highly recommend them.",
    category: "Education",
  },
  {
    id: 8,
    time: "Since 1999",
    name: "SRG Apperiels",
    link: "https://srgapparels.com/",
    description:
      "We trust Mayurifabs for all our construction material needs. Their commitment to sustainability and quality aligns perfectly with our values.",
    category: "Apparel",
  },
  {
    id: 9,
    time: "Since 1963",
    name: "LNT Coimbatore",
    link: "https://www.larsentoubro.com/corporate/about-lt-group/facilities/india/coimbatore-tamil-nadu/",
    description:
      "Mayurifabs has played a crucial role in the success of our large-scale projects. Their expertise and quality products are invaluable to us.",
    category: "Engineering",
  },
];

export {
  missionAboutus,
  mayuriAboutus,
  customersAboutus,
  ourTeam,
  clientsAboutus,
};
