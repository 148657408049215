import React, { useState } from "react";

const questions = [
  {
    id: 1,
    question: "What are the benefits of fiber pots?",
    answer:
      "Fiber pots are lightweight, durable, and weather-resistant. They are eco-friendly, easy to maintain, and available in various sizes and designs for indoor and outdoor use.",
  },
  {
    id: 2,
    question: "Why choose fiber doors for your home?",
    answer:
      "Fiber doors are stylish, durable, and secure. They are weather-resistant, low-maintenance, and energy-efficient, providing an elegant and modern look to your home.",
  },
  {
    id: 3,
    question: "What are the advantages of modular kitchens?",
    answer:
      "Modular kitchens are customizable, space-efficient, and easy to install. They offer a modern, functional design and come in various finishes and accessories to suit your taste and budget.",
  },
  {
    id: 4,
    question: "Why opt for UPVC doors?",
    answer:
      "UPVC doors are energy-efficient, secure, and durable. They are low-maintenance, weather-resistant, and available in various styles and colors to enhance your home.",
  },
  {
    id: 5,
    question: "What are the benefits of PVC doors?",
    answer:
      "PVC doors are strong, durable, and cost-effective. They are low-maintenance, weather-resistant, and available in various designs and finishes to complement your decor.",
  },
  {
    id: 6,
    question: "Why choose Mayuri Fabrication for manhole covers?",
    answer:
      "Mayuri Fabrication offers high-quality manhole covers that are durable, secure, and easy to install. Our covers provide maximum protection and safety with various sizes and designs.",
  },
  {
    id: 7,
    question: "What are the benefits of fiber-reinforced plastic solutions?",
    answer:
      "Fiber-reinforced plastic solutions are lightweight, durable, and versatile. They are weather-resistant, eco-friendly, and cost-effective, offering a sustainable alternative to traditional materials.",
  },
  {
    id: 8,
    question: "Why choose Mayuri Fabrication for fiber products?",
    answer:
      "Mayuri Fabrication provides high-quality fiber products, including pots, doors, and planters. Our products enhance your space with beauty and functionality, offering durable and stylish solutions.",
  },
  {
    id: 9,
    question: "What sets Mayuri Fabrication apart from other providers?",
    answer:
      "Mayuri Fabrication delivers exceptional service and innovative fiber solutions. Our products meet high standards of quality and durability, focusing on customer satisfaction and value.",
  },
  {
    id: 10,
    question: "How can I contact Mayuri Fabrication for more information?",
    answer:
      "Contact us at +91 9952338221, +91 8122456677, or email mayurifabs@gmail.com for more information. Our team is available to assist with your fiber needs.",
  },
];

const QuestionsData = ({ answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => setIsExpanded(!isExpanded);

  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + "...";
  };

  return (
    <p className="text-base font-light text-gray-300 pt-1 text-left">
      {isExpanded ? answer : truncateText(answer, 150)}
      <span
        className="text-no2 font-semibold cursor-pointer"
        onClick={toggleReadMore}
      >
        {isExpanded ? " Read Less" : " Read More"}
      </span>
    </p>
  );
};

export { questions, QuestionsData };
