import React from "react";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";

const Counts = ({ bgColor }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  return (
    <div
      ref={ref}
      className="pt-5 flex items-center justify-around lg:justify-start lg:items-start gap-x-5 flex-col text-center 2xl:pr-2 md:flex-row w-full 2xl:ml-10"
    >
      <div className="flex md:hidden justify-between items-center w-full pb-4 gap-x-6">
        <div className={`${bgColor} text-white py-5 px-7 rounded-2xl w-full`}>
          <p className="text-2xl font-semibold">
            {inView ? (
              <CountUp start={0} end={200} duration={2} suffix="+" />
            ) : (
              "200+"
            )}
          </p>
          <p className="text-gray-400 text-xs">Happy Customers</p>
        </div>
        <div className={`${bgColor} text-white py-5 px-7 rounded-2xl w-full`}>
          <p className="text-2xl font-semibold">
            {inView ? (
              <CountUp start={0} end={10} duration={2} suffix="K+" />
            ) : (
              "10K+"
            )}
          </p>
          <p className="text-gray-400 text-xs">Products Sold</p>
        </div>
      </div>
      <div
        className={`${bgColor} text-white py-5 px-7 rounded-2xl hidden md:flex flex-col w-full`}
      >
        <p className="text-2xl font-semibold">
          {inView ? (
            <CountUp start={0} end={200} duration={2} suffix="+" />
          ) : (
            "200+"
          )}
        </p>
        <p className="text-gray-400">Happy Customers</p>
      </div>
      <div
        className={`${bgColor} text-white py-5 px-7 rounded-2xl hidden md:flex flex-col w-full`}
      >
        <p className="text-2xl font-semibold">
          {inView ? (
            <CountUp start={0} end={10} duration={2} suffix="K+" />
          ) : (
            "10K+"
          )}
        </p>
        <p className="text-gray-400">Products Sold</p>
      </div>
      <div
        className={`${bgColor} text-white py-5 px-28 rounded-2xl md:px-7 flex flex-col w-full`}
      >
        <p className="text-2xl font-semibold">
          {inView ? (
            <CountUp start={0} end={11} duration={2} suffix="+" />
          ) : (
            "11+"
          )}
        </p>
        <p className="text-gray-400">Years Experience</p>
      </div>
    </div>
  );
};

export default Counts;
