import React, { useState } from "react";

import products from "./assets/products.webp";
import FibrePots from "./assets/pots/fibrePots";
import ModularKitchen from "./assets/ModularKitchen/modularKitchen";
import FibreDoors from "./assets/FibreDoors/fibreDoor";
import PvcDoors from "./assets/PvcDoors/pvcDoors";
import Manhole from "./assets/manhole/manhole";
import Loader from "../Components/Loader/Loader";

const Products = () => {
  const [search, setSearch] = useState("");
  const [selectedLink, setSelectedLink] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const links = [
    { name: "Fibre Doors", component: <FibreDoors /> },
    { name: "PVC Doors", component: <PvcDoors /> },
    { name: "Modular Kitchen", component: <ModularKitchen /> },
    { name: "Fibre Pots", component: <FibrePots /> },
    { name: "Manhole", component: <Manhole /> },
  ];

  const filteredLinks = links.filter((link) =>
    link.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <div className="p-3 lg:p-5 font-urbanist font-semibold">
        <div className="w-full relative">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white">
              <Loader />
            </div>
          )}
          <img
            src={products}
            alt="fibredoors"
            className={`w-full h-auto sm:h-64 md:h-96 xl:h-[600px] rounded-xl border-2 border-gray-400 bg-white ${
              isLoading ? "hidden" : "block"
            }`}
            onLoad={() => {
              setIsLoading(false);
            }}
            onError={() => {
              setIsLoading(false);
            }}
          />
          <h1 className="heading-custom mt-5">
            Elevate Your Doorscapes with FRP and PVC Solutions
          </h1>
          <p className="paragraph-custom">
            Transform your entryways and interior spaces with Mayuri
            Fabrication's premium range of FRP doors and versatile PVC
            solutions. Combining exceptional quality with modern design, our
            products offer unmatched durability, functionality, and style to
            suit all your needs.
          </p>
        </div>
        <div className="bg-no1 p-4 font-urbanist">
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="mb-4 p-4 border border-gray-300 rounded-3xl w-full"
          />
          <div className="mb-4 flex flex-wrap gap-2">
            {filteredLinks.map((link, index) => (
              <button
                key={index}
                onClick={() => setSelectedLink(link.name)}
                className="px-4 py-2 bg-no2 text-white rounded-2xl hover:bg-no2"
              >
                {link.name}
              </button>
            ))}
          </div>
          <div
            className={`content ${
              selectedLink ? "h-96" : "h-0"
            } overflow-y-auto transition-height duration-300`}
          >
            {selectedLink &&
              links.find((link) => link.name === selectedLink).component}
          </div>
        </div>
        <FibreDoors />
        <PvcDoors />
        <ModularKitchen />
        <FibrePots />
        <Manhole />
      </div>
    </>
  );
};

export default Products;
