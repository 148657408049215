import React, { useRef } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { FaEnvelope, FaPaperPlane } from "react-icons/fa";

const ContactForm = () => {
  const [state, handleSubmit] = useForm("mjkbvqqw");
  const formRef = useRef(null);

  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }

  const handleIconClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit}
      className="relative flex items-center space-x-2 w-full"
    >
      <div className="relative w-full">
        <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Enter your email"
          className="border-none rounded pl-10 pr-10 py-3 w-full text-sm"
        />
        <FaPaperPlane
          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-500 cursor-pointer"
          onClick={handleIconClick}
        />
      </div>
      <ValidationError prefix="Email" field="email" errors={state.errors} />
    </form>
  );
};

export default ContactForm;
