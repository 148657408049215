import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";

Modal.setAppElement("#root");

const OurTerms = forwardRef((props, ref) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const modalRef = useRef();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (modalIsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalIsOpen]);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Terms and Conditions Modal"
        className="fixed inset-0 flex items-center justify-center p-4 z-50 top-20 md:top-0"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        shouldCloseOnOverlayClick={true}
      >
        <div
          ref={modalRef}
          className="bg-white rounded-lg bottom-10 top-5 shadow-lg p-6 w-full max-w-3xl z-50 relative overflow-y-auto"
          style={{ maxHeight: "70vh" }}
        >
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-red-500 flex items-center"
          >
            <FaTimes className="mr-2 w-6 h-6" />
          </button>
          <h2 className="text-xl font-semibold mb-4">Terms and Conditions</h2>
          <ul className="list-disc pl-5">
            <li>
              50% advance payment, 30% supply advance, balance after completion
            </li>
            <li>Service guarantee only, not for physical damage</li>
            <li>Transport charges apply</li>
            <li>No changes to applied colors and designs by users</li>
            <li>Colors may vary in real life</li>
            <li>Work will not start without confirmation</li>
            <li>Transport damage is at the user's risk</li>
            <li>
              All payments must be made in accordance with the agreed schedule
            </li>
            <li>We reserve the right to modify these terms at any time</li>
            <li>Users must comply with all applicable laws and regulations</li>
            <li>Products are provided "as is" without any warranties</li>
            <li>
              Limitation of liability: We are not liable for any indirect,
              incidental, or consequential damages
            </li>
            <li>
              Governing law: These terms are governed by the laws of the
              applicable jurisdiction
            </li>
            <li>
              Dispute resolution: Any disputes will be resolved through binding
              arbitration
            </li>
            <li>
              Privacy: User data will be handled in accordance with our privacy
              policy
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
});

export default OurTerms;
