import manhole from "./1.webp";
import manhole1 from "./2.webp";
import manhole2 from "./3.webp";
import manhole3 from "./4.webp";
import manholeVideo from "./manholeVideo.mp4";
import manholeVideo1 from "./manholevideo1.mp4";

const Manhole = () => {
  return (
    <div className="p-3 my-10 lg:p-5 font-urbanist font-semibold border-2 border-no1">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
        {/* First Video on Top */}
        <div className="col-span-1 md:col-span-2 lg:col-span-2">
          <h1 className="heading-custom mt-5 text-no2">
            Discover Our Manhole Collection
          </h1>
          <p className="paragraph-custom">
            Explore the versatility and durability of our manhole covers. Watch
            the video to see them in action.
          </p>
          <video
            src={manholeVideo}
            autoPlay
            muted
            loop
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[600px] rounded-xl border-2 border-gray-400 bg-white"
          />
        </div>

        {/* Card 1 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={manhole}
            alt="manhole cover"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl"
          />
          <h1 className="heading-custom mt-5">Heavy-Duty Manhole Covers</h1>
          <p className="paragraph-custom">
            Our heavy-duty manhole covers are designed to withstand the toughest
            conditions, ensuring long-lasting performance.
          </p>
        </div>

        {/* Card 2 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={manhole1}
            alt="manhole cover"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">Lightweight and Durable</h1>
          <p className="paragraph-custom">
            Our lightweight manhole covers offer easy handling without
            compromising on strength and durability.
          </p>
        </div>

        {/* Card 3 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={manhole2}
            alt="manhole cover"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">Customizable Designs</h1>
          <p className="paragraph-custom">
            Choose from a variety of designs and sizes to meet your specific
            needs. Our customizable manhole covers are perfect for any project.
          </p>
        </div>

        {/* Card 4 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={manhole3}
            alt="manhole cover"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl"
          />
          <h1 className="heading-custom mt-5">Eco-Friendly Materials</h1>
          <p className="paragraph-custom">
            Our manhole covers are made from eco-friendly materials, ensuring
            sustainability without sacrificing quality.
          </p>
        </div>
      </div>
      <video
        src={manholeVideo1}
        autoPlay
        muted
        loop
        className="w-full h-auto mt-10 sm:h-64 md:hidden rounded-xl border-2 border-gray-400 bg-no1"
      />
    </div>
  );
};

export default Manhole;
