import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { QuestionsData, questions } from "./QuestionsData";
import doorStars from "../../assets/starsDoors.png";

const Questions = () => {
  const [slidesToShow, setSlidesToShow] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setSlidesToShow(3);
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    centerPadding: "20px",
  };

  return (
    <>
      <div className="p-5 pt-10 font-semibold font-urbanist">
        <hr className="h-0.5 bg-gray-400 w-full rounded-3xl mb-5" />
        <img
          src={doorStars}
          alt="stars doors"
          className="w-14 h-6 md:w-20 md:h-8"
        />
        <p className="text-2xl md:text-4xl lg:text-5xl font-semibold py-5">
          Frequently Asked Questions
        </p>
        <p className="text-sm md:text-base lg:text-xl font-light text-gray-500 lg:w-[80%]">
          Find answers to common questions about Mayuri Fabrication's products,
          collections, and services. We're here to provide clarity and assist
          you every step of the way.
        </p>
      </div>
      <Slider {...settings}>
        {questions.map((question) => (
          <div key={question.id} className="p-2">
            <div className="flex flex-col justify-between h-full md:min-h-[220px] items-start font-urbanist w-full py-10 bg-black text-white p-5 rounded-lg shadow-lg">
              <p className="text-lg font-semibold">{question.question}</p>
              <QuestionsData answer={question.answer} />
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default Questions;
