import React from "react";
import mayuriLogo from "../../assets/Mayuri_Logo.webp";

const Loader = () => {
  return (
    <div className="flex items-center justify-center bg-white">
      <div className="relative flex items-center justify-center">
        <img src={mayuriLogo} alt="Mayuri Logo" className="h-10 w-10 z-10" />
        <div
          className="absolute h-20 w-20 rounded-full border-4 border-transparent animate-spin"
          style={{
            borderTopColor: "#00FFF1",
            borderRightColor: "#0C7BC3",
            borderBottomColor: "#00FFF1",
            borderLeftColor: "#0C7BC3",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Loader;
