import React, { useState } from "react";

const customers = [
  {
    id: 1,
    name: "Prakash R",
    star: 5,
    city: "Chennai",
    title: "Service Stellar",
    review:
      "Mayuri Fabrication provided exceptional service. The product quality is outstanding, and the staff is both professional and helpful. I highly recommend them for fiber-reinforced plastic solutions. Truly a great experience.",
  },
  {
    id: 2,
    name: "Suresh K",
    star: 5,
    city: "Coimbatore",
    title: "Kitchen Delight",
    review:
      "I recently bought a modular kitchen from Mayuri Fabrication and couldn't be happier. The design is stunning, and the quality is superb. I highly recommend their products for anyone seeking durable and stylish fiber solutions.",
  },
  {
    id: 3,
    name: "Latha S",
    star: 5,
    city: "Madurai",
    title: "Door Durability",
    review:
      "I purchased UPVC doors from Mayuri Fabrication and am very impressed with their quality and durability. The doors are stylish and secure, and the installation was efficient. I recommend Mayuri Fabrication for high-quality doors. Excellent service.",
  },
  {
    id: 4,
    name: "Rajesh M",
    star: 5,
    city: "Trichy",
    title: "Garden Perfect",
    review:
      "I recently bought fiber pots from Mayuri Fabrication and am very pleased with the product. The pots are sturdy and weather-resistant, perfect for my garden. The staff was helpful, and the delivery was prompt. I highly recommend Mayuri Fabrication for fiber products.",
  },
  {
    id: 5,
    name: "Vijay S",
    star: 5,
    city: "Salem",
    title: "Secure Doors",
    review:
      "I purchased PVC doors from Mayuri Fabrication and am extremely satisfied with their quality and design. The doors are durable and secure, and the installation was professional. I highly recommend Mayuri Fabrication for high-quality doors. Great experience.",
  },
  {
    id: 6,
    name: "Anitha R",
    star: 5,
    city: "Mysore",
    title: "Manhole Excellence",
    review:
      "I recently bought manhole covers from Mayuri Fabrication and am very impressed with their quality and durability. The covers are sturdy and secure, and the installation was efficient. I recommend Mayuri Fabrication for high-quality manhole covers. Excellent service.",
  },
  {
    id: 7,
    name: "Rajesh K",
    star: 5,
    city: "Bangalore",
    title: "Fiber Linings",
    review:
      "I purchased fiber linings from Mayuri Fabrication and am extremely satisfied with their quality and design. The linings are durable and stylish, and the installation was professional. I highly recommend Mayuri Fabrication for high-quality fiber linings. Great experience.",
  },
  {
    id: 8,
    name: "Saranya R",
    star: 5,
    city: "Hyderabad",
    title: "Garden Delight",
    review:
      "I recently bought fiber pots from Mayuri Fabrication and am very pleased with the product. The pots are sturdy and weather-resistant, perfect for my garden. The staff was helpful, and the delivery was prompt. I highly recommend Mayuri Fabrication for fiber products.",
  },
  {
    id: 9,
    name: "Vijay K",
    star: 5,
    city: "Vijayawada",
    title: "Quality Doors",
    review:
      "I purchased PVC doors from Mayuri Fabrication and am extremely satisfied with their quality and design. The doors are durable and secure, and the installation was professional. I highly recommend Mayuri Fabrication for high-quality doors. Great experience.",
  },
  {
    id: 10,
    name: "Anitha S",
    star: 5,
    city: "Kerala",
    title: "Manhole Covers",
    review:
      "I recently bought manhole covers from Mayuri Fabrication and am very impressed with their quality and durability. The covers are sturdy and secure, and the installation was efficient. I recommend Mayuri Fabrication for high-quality manhole covers. Excellent service.",
  },
];

const CustomersReview = ({ review }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => setIsExpanded(!isExpanded);

  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + "...";
  };

  return (
    <p className="text-base font-light text-gray-300 pt-1 text-left">
      {isExpanded ? review : truncateText(review, 150)}
      <span
        className="text-no2 font-semibold cursor-pointer"
        onClick={toggleReadMore}
      >
        {isExpanded ? " Read Less" : " Read More"}
      </span>
    </p>
  );
};

export { customers, CustomersReview };
