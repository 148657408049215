import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";

Modal.setAppElement("#root");

const PrivacyPolicy = forwardRef((props, ref) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const modalRef = useRef();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (modalIsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalIsOpen]);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Privacy Policy Modal"
        className="fixed inset-0 flex items-center justify-center p-4 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        shouldCloseOnOverlayClick={true}
      >
        <div
          ref={modalRef}
          className="bg-white rounded-lg shadow-lg p-6 w-full max-w-3xl z-50 relative overflow-y-auto"
          style={{ maxHeight: "80vh" }}
        >
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-red-500 flex items-center"
          >
            <FaTimes className="mr-2 w-6 h-6" />
          </button>
          <h2 className="text-xl font-semibold mb-4">Privacy Policy</h2>
          <ul className="list-disc pl-5">
            <li>No return guarantee</li>
            <li>If the fault is on our side, we will address it</li>
            <li>Data is kept private and secure</li>
            <li>Product delivery depends on quantity and distance</li>
            <li>
              Advance notice required 2 days before return; returns must be made
              within 2 days
            </li>
            <li>We collect personal information to provide better services</li>
            <li>
              We do not share personal information with third parties without
              consent
            </li>
            <li>Users can access and update their personal information</li>
            <li>We use cookies to enhance user experience</li>
            <li>We comply with applicable data protection laws</li>
          </ul>
        </div>
      </Modal>
    </div>
  );
});

export default PrivacyPolicy;
