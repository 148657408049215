import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";

const Inquiry = () => {
  const [state, handleSubmit] = useForm("mblrykej");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    inquiryType: "",
    hearAboutUs: "",
    message: "",
    terms: false,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.phone) newErrors.phone = "Phone is required";
    if (!formData.inquiryType)
      newErrors.inquiryType = "Inquiry Type is required";
    if (!formData.hearAboutUs) newErrors.hearAboutUs = "This field is required";
    if (!formData.message) newErrors.message = "Message is required";
    if (!formData.terms)
      newErrors.terms = "You must agree to the terms and conditions";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    await handleSubmit(e);
    if (state.succeeded) {
      setFormData({
        name: "",
        email: "",
        phone: "",
        inquiryType: "",
        hearAboutUs: "",
        message: "",
        terms: false,
      });
    }
  };

  return (
    <form
      onSubmit={handleFormSubmit}
      className="space-y-4 p-4 max-w-lg mx-auto font-urbanist w-full mt-10 lg:mt-20"
    >
      <div>
        <h1 className="text-2xl font-bold text-center">Send Inquiry</h1>
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700 font-urbanist w-full"
        >
          Your Name
        </label>
        <input
          id="name"
          type="text"
          name="name"
          placeholder="Enter your name"
          value={formData.name}
          onChange={handleChange}
          className={`mt-1 block w-full border ${
            errors.name ? "border-red-500" : "border-gray-300"
          } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
        />
        {errors.name && (
          <p className="text-red-500 text-xs mt-1">{errors.name}</p>
        )}
      </div>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Email Address
        </label>
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Enter your email"
          value={formData.email}
          onChange={handleChange}
          className={`mt-1 block w-full border ${
            errors.email ? "border-red-500" : "border-gray-300"
          } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        {errors.email && (
          <p className="text-red-500 text-xs mt-1">{errors.email}</p>
        )}
      </div>
      <div>
        <label
          htmlFor="phone"
          className="block text-sm font-medium text-gray-700"
        >
          Phone
        </label>
        <input
          id="phone"
          type="tel"
          name="phone"
          placeholder="Enter your phone number"
          value={formData.phone}
          onChange={handleChange}
          className={`mt-1 block w-full border ${
            errors.phone ? "border-red-500" : "border-gray-300"
          } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
        />
        {errors.phone && (
          <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
        )}
      </div>
      <div>
        <label
          htmlFor="inquiryType"
          className="block text-sm font-medium text-gray-700"
        >
          Inquiry Type
        </label>
        <select
          id="inquiryType"
          name="inquiryType"
          value={formData.inquiryType}
          onChange={handleChange}
          className={`mt-1 block w-full border ${
            errors.inquiryType ? "border-red-500" : "border-gray-300"
          } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
        >
          <option value="">Select a Product</option>
          <option value="Fibre doors">Fibre doors</option>
          <option value="Fibre pots">Fibre pots</option>
          <option value="Manhole">Manhole</option>
          <option value="PVC door">PVC door</option>
          <option value="Modular kitchen">Modular kitchen</option>
          <option value="Other">Other</option>
        </select>
        {errors.inquiryType && (
          <p className="text-red-500 text-xs mt-1">{errors.inquiryType}</p>
        )}
      </div>
      <div>
        <label
          htmlFor="hearAboutUs"
          className="block text-sm font-medium text-gray-700"
        >
          How did you hear about us?
        </label>
        <select
          id="hearAboutUs"
          name="hearAboutUs"
          value={formData.hearAboutUs}
          onChange={handleChange}
          className={`mt-1 block w-full border ${
            errors.hearAboutUs ? "border-red-500" : "border-gray-300"
          } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
        >
          <option value="">Select a Platform</option>
          <option value="Facebook">Facebook</option>
          <option value="Instagram">Instagram</option>
          <option value="YouTube">YouTube</option>
          <option value="Twitter">Twitter</option>
          <option value="Other">Other</option>
        </select>
        {errors.hearAboutUs && (
          <p className="text-red-500 text-xs mt-1">{errors.hearAboutUs}</p>
        )}
      </div>
      <div>
        <label
          htmlFor="message"
          className="block text-sm font-medium text-gray-700"
        >
          Message
        </label>
        <textarea
          id="message"
          name="message"
          rows={4}
          placeholder="Enter your message"
          value={formData.message}
          onChange={handleChange}
          className={`mt-1 block w-full border ${
            errors.message ? "border-red-500" : "border-gray-300"
          } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        {errors.message && (
          <p className="text-red-500 text-xs mt-1">{errors.message}</p>
        )}
      </div>
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id="terms"
            name="terms"
            type="checkbox"
            checked={formData.terms}
            onChange={handleChange}
            className={`focus:ring-indigo-500 h-4 w-4 ${
              errors.terms ? "border-red-500" : "border-gray-300"
            } text-indigo-600 rounded`}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="terms" className="font-medium text-gray-700">
            I agree with Terms and Conditions
          </label>
        </div>
      </div>
      <div>
        <button
          type="submit"
          disabled={state.submitting}
          className="w-full flex justify-center py-4 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-no2 hover:bg-no2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Send your Message
        </button>
      </div>
    </form>
  );
};

export default Inquiry;
