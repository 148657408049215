import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";

import { questions } from "../../../Home/Questions/QuestionsData";

Modal.setAppElement("#root");

const FaqsModal = forwardRef((props, ref) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const modalRef = useRef();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (modalIsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalIsOpen]);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="FAQs Modal"
        className="fixed inset-0 flex items-center justify-center p-4 z-50 top-52 md:top-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        shouldCloseOnOverlayClick={true}
      >
        <div
          ref={modalRef}
          className="bg-white rounded-lg bottom-8 shadow-lg p-6 w-full max-w-3xl z-50 relative overflow-y-auto"
          style={{ maxHeight: "70vh" }}
        >
          <button
            onClick={closeModal}
            className="absolute top-4 right-4  text-no2 flex items-center"
          >
            <FaTimes className="mr-2 w-6 h-6 rounded-full" />
          </button>
          <h2 className="text-xl font-semibold mb-4">
            Frequently Asked Questions
          </h2>
          <ul>
            {questions.map((q) => (
              <li key={q.id} className="my-4">
                <h3 className="font-bold">{q.question}</h3>
                <p>{q.answer}</p>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </div>
  );
});

export default FaqsModal;
