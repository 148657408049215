import kitchen from "./1.webp";
import kitchen1 from "./2.webp";
import kitchen2 from "./3.webp";
import kitchen3 from "./4.webp";
import kitchenVideo from "./kitchenvideo.mp4";
import kitchenVideo1 from "./kitchenvideo2.mp4";

const ModularKitchen = () => {
  return (
    <div className="p-3 my-10 lg:p-5 font-urbanist font-semibold border-2 border-no1">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
        {/* First Video on Top */}
        <div className="col-span-1 md:col-span-2 lg:col-span-2">
          <h1 className="heading-custom mt-5 text-no2">
            Watch Our Modular Kitchens in Action
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our modular kitchens in
            action. Watch our video to see how our kitchens can transform your
            spaces and elevate your interiors and exteriors with style and
            durability.
          </p>
          <video
            src={kitchenVideo1}
            autoPlay
            muted
            loop
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[600px] rounded-xl border-2 border-gray-400 bg-white"
          />
        </div>

        {/* Card 1 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={kitchen}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl"
          />
          <h1 className="heading-custom mt-5">
            Elevate Your Kitchenscapes with FRP and PVC Solutions
          </h1>
          <p className="paragraph-custom">
            Transform your entryways and interior spaces with Mayuri
            Fabrication's premium range of modular kitchens and versatile PVC
            solutions. Combining exceptional quality with modern design, our
            products offer unmatched durability, functionality, and style to
            suit all your needs.
          </p>
        </div>

        {/* Card 2 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={kitchen1}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            Modular Kitchens: The Ultimate Choice for Durability and Style
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our modular kitchens in
            action. Watch our video to see how our kitchens can transform your
            spaces and elevate your interiors and exteriors with style and
            durability.
          </p>
        </div>

        {/* Card 3 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={kitchen2}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            Modular Kitchens: The Ultimate Choice for Durability and Style
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our modular kitchens in
            action. Watch our video to see how our kitchens can transform your
            spaces and elevate your interiors and exteriors with style and
            durability.
          </p>
        </div>

        {/* Card 4 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={kitchen3}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            Modular Kitchens: The Ultimate Choice for Durability and Style
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our modular kitchens in
            action. Watch our video to see how our kitchens can transform your
            spaces and elevate your interiors and exteriors with style and
            durability.
          </p>
        </div>
      </div>
      <video
        src={kitchenVideo}
        autoPlay
        muted
        loop
        className="w-full h-auto mt-10 sm:h-64 md:hidden rounded-xl border-2 border-gray-400 bg-no1"
      />
    </div>
  );
};

export default ModularKitchen;
