import fibredoor1 from "./1.webp";
import fibredoor2 from "./2.webp";
import fibredoor3 from "./3.webp";
import fibredoor4 from "./4.webp";
import fibredoor5 from "./5.webp";
import fibredoor6 from "./6.webp";
import fibreVideo from "./doorsVideo1.mp4";
import fibreVideo1 from "./doorVideos2.mp4";

const FibreDoors = () => {
  return (
    <div className="p-3 lg:p-5 font-urbanist font-semibold border-2 border-no1">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {/* First Video on Top */}
        <div className="col-span-1 md:col-span-2 lg:col-span-3">
          <h1 className="heading-custom mt-5 text-no2">
            Watch Our FRP Doors in Action
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our FRP doors in action.
            Watch our video to see how our doors can transform your spaces and
            elevate your interiors and exteriors with style and durability.
          </p>
          <video
            src={fibreVideo1}
            autoPlay
            muted
            loop
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[600px] rounded-xl border-2 border-gray-400 bg-white"
          />
        </div>

        {/* Card 1 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={fibredoor1}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            Elevate Your Doorscapes with FRP and PVC Solutions
          </h1>
          <p className="paragraph-custom">
            Transform your entryways and interior spaces with Mayuri
            Fabrication's premium range of FRP doors and versatile PVC
            solutions. Combining exceptional quality with modern design, our
            products offer unmatched durability, functionality, and style to
            suit all your needs.
          </p>
        </div>

        {/* Card 2 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={fibredoor2}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            FRP Doors: The Ultimate Choice for Durability and Style
          </h1>
          <p className="paragraph-custom">
            Our FRP doors are crafted with precision to provide superior
            strength, durability, and style to your spaces. Made from
            high-quality materials, these doors are resistant to moisture, heat,
            and corrosion, making them ideal for both indoor and outdoor
            applications.
          </p>
        </div>

        {/* Card 3 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={fibredoor3}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            PVC Doors: Versatile Solutions for Modern Spaces
          </h1>
          <p className="paragraph-custom">
            Our PVC doors are designed to offer unmatched versatility and style
            to your interiors. Available in a wide range of designs, colors, and
            finishes, these doors are perfect for modern homes, offices, and
            commercial spaces.
          </p>
        </div>

        {/* Card 4 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={fibredoor4}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            Custom FRP and PVC Doors: Tailored to Your Needs and Style
            Preferences
          </h1>
          <p className="paragraph-custom">
            At Mayuri Fabrication, we offer custom FRP and PVC doors that are
            tailored to your specific needs and style preferences. Whether you
            are looking for a unique design, color, or finish, our team can
            create the perfect door to complement your space.
          </p>
        </div>

        {/* Card 5 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={fibredoor5}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            FRP and PVC Doors: The Perfect Combination of Style and
            Functionality
          </h1>
          <p className="paragraph-custom">
            Our FRP and PVC doors are designed to offer the perfect combination
            of style and functionality to your spaces. Whether you are looking
            for a modern design, traditional style, or contemporary finish, our
            doors are sure to elevate your interiors and exteriors.
          </p>
        </div>

        {/* Card 6 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={fibredoor6}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            Elevate Your Spaces with Mayuri Fabrication's Premium FRP and PVC
            Doors
          </h1>
          <p className="paragraph-custom">
            Transform your spaces with Mayuri Fabrication's premium range of FRP
            and PVC doors. Crafted with precision and attention to detail, our
            doors offer unmatched quality, durability, and style to suit all
            your needs. Elevate your spaces with Mayuri Fabrication today!
          </p>
        </div>

        {/* Second Video */}
        <div className="col-span-1 md:col-span-2 lg:col-span-3 md:hidden">
          <video
            src={fibreVideo}
            autoPlay
            loop
            muted
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[600px] rounded-xl border-2 border-gray-400 bg-white"
          />
        </div>
      </div>
    </div>
  );
};

export default FibreDoors;
