import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "react-icons/fa";

import starsDoors from "../../assets/starsDoors.png";
import { customers, CustomersReview } from "./CustomersData";

const Customers = () => {
  const [slidesToShow, setSlidesToShow] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setSlidesToShow(3);
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerPadding: "20px",
  };

  return (
    <>
      <div className="px-5 pt-5 font-semibold font-urbanist">
        <img
          src={starsDoors}
          alt="stars doors"
          className="w-14 h-6 md:w-20 md:h-8"
        />
        <p className="text-2xl md:text-4xl lg:text-5xl font-semibold py-5">
          Trusted by Our Valued Customers at Mayuri Fabrication
        </p>
        <p className="text-sm md:text-base lg:text-xl font-light text-gray-500 lg:w-[80%]">
          Discover why customers trust Mayuri Fabrication for their innovative
          fiber solutions. From durable and secure doors to stylish and
          weather-resistant planters, our products elevate your space with
          beauty and functionality. Read our customer reviews to see how Mayuri
          Fabrication has transformed their homes and gardens.
        </p>
      </div>
      <Slider {...settings}>
        {customers.map((customer) => (
          <div key={customer.id} className="p-2">
            <div className="flex mt-5 flex-col items-start font-urbanist justify-center w-full pt-5 bg-black text-white p-5 rounded-lg shadow-lg">
              <div className="flex items-center flex-col text-center justify-center">
                <div className="flex items-start justify-start w-full gap-x-5 mt-5">
                  {[...Array(customer.star)].map((_, index) => (
                    <FaStar key={index} color="gold" />
                  ))}
                </div>
              </div>
              <p className="text-lg font-semibold pt-2 mt-5">
                {customer.title}
              </p>
              <CustomersReview review={customer.review} />
              <p className="text-xl font-semibold mt-5">{customer.name}</p>
              <p className="text-lg font-semibold text-gray-500">
                {customer.city}
              </p>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default Customers;
