import pot from "./1.webp";
import pot1 from "./2.webp";
import pot2 from "./3.webp";
import pot3 from "./4.webp";
import pot4 from "./5.webp";
import pot5 from "./6.webp";
import potVideo from "./potsvideo.mp4";
import potVideo1 from "./potsvideo1.mp4";

const FibrePots = () => {
  return (
    <div className="p-3 my-10 lg:p-5 font-urbanist font-semibold border-2 border-no1">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {/* First Video on Top */}
        <div className="col-span-1 md:col-span-2 lg:col-span-3">
          <h1 className="heading-custom mt-5 text-no2">
            Watch Our FRP Pots in Action
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our FRP pots in action.
            Watch our video to see how our pots can transform your spaces and
            elevate your interiors and exteriors with style and durability.
          </p>
          <video
            src={potVideo}
            autoPlay
            muted
            loop
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[600px] rounded-xl border-2 border-gray-400 bg-white"
          />
        </div>

        {/* Card 1 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={pot}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl"
          />
          <h1 className="heading-custom mt-5">
            Elevate Your Potscapes with FRP and PVC Solutions
          </h1>
          <p className="paragraph-custom">
            Transform your entryways and interior spaces with Mayuri
            Fabrication's premium range of FRP pots and versatile PVC solutions.
            Combining exceptional quality with modern design, our products offer
            unmatched durability, functionality, and style to suit all your
            needs.
          </p>
        </div>

        {/* Card 2 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={pot1}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            FRP Pots: The Ultimate Choice for Durability and Style
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our FRP pots in action.
            Watch our video to see how our pots can transform your spaces and
            elevate your interiors and exteriors with style and durability.
          </p>
        </div>

        {/* Card 3 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={pot2}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            FRP Pots: The Ultimate Choice for Durability and Style
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our FRP pots in action.
            Watch our video to see how our pots can transform your spaces and
            elevate your interiors and exteriors with style and durability.
          </p>
        </div>

        {/* Card 4 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={pot3}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            FRP Pots: The Ultimate Choice for Durability and Style
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our FRP pots in action.
            Watch our video to see how our pots can transform your spaces and
            elevate your interiors and exteriors with style and durability.
          </p>
        </div>

        {/* Card 5 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={pot4}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            FRP Pots: The Ultimate Choice for Durability and Style
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our FRP pots in action.
            Watch our video to see how our pots can transform your spaces and
            elevate your interiors and exteriors with style and durability.
          </p>
        </div>

        {/* Card 6 */}
        <div className="bg-white rounded-xl border-2 border-gray-400 p-5">
          <img
            src={pot5}
            alt="fibredoors"
            className="w-full h-auto sm:h-64 md:h-96 xl:h-[300px] rounded-xl bg-no1"
          />
          <h1 className="heading-custom mt-5">
            FRP Pots: The Ultimate Choice for Durability and Style
          </h1>
          <p className="paragraph-custom">
            Discover the beauty and functionality of our FRP pots in action.
            Watch our video to see how our pots can transform your spaces and
            elevate your interiors and exteriors with style and durability.
          </p>
        </div>
      </div>
      <video
        src={potVideo1}
        autoPlay
        muted
        loop
        className="w-full mt-10 h-auto sm:h-64 md:hidden rounded-xl border-2 border-gray-400 bg-no1"
      />
    </div>
  );
};

export default FibrePots;
