import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa"; // Import the icon

Modal.setAppElement("#root"); // Set the root element for accessibility

const Map = ({ src, width = "600", height = "450" }) => {
  return (
    <iframe
      src={src}
      width={width}
      height={height}
      className="border-0 w-full h-96 md:h-[450px] z-50"
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Map"
    ></iframe>
  );
};

const MapsModal = forwardRef((props, ref) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [mapSrc, setMapSrc] = useState("");
  const [mapLabel, setMapLabel] = useState("");
  const modalRef = useRef();

  const openModal = (src, label) => {
    setMapSrc(src);
    setMapLabel(label);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setMapSrc("");
    setMapLabel("");
  };

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (modalIsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalIsOpen]);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Map Modal"
        className="fixed inset-0 flex items-center justify-center p-4 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        shouldCloseOnOverlayClick={true}
      >
        <div
          ref={modalRef}
          className="bg-white rounded-lg shadow-lg p-6 w-full max-w-3xl z-50 relative"
        >
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-no2 flex items-center"
          >
            <FaTimes className="mr-2 w-6 h-6 rounded-full" />
          </button>
          <h2 className="text-xl font-semibold mb-4">{mapLabel} Map</h2>
          <Map src={mapSrc} />
        </div>
      </Modal>
    </div>
  );
});

export default MapsModal;
